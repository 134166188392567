<template>
  <!-- Start desktop table -->
  <el-table
    class="desktop-user-table"
    :data="items"
    style="width: 100%"
    :border="true"
    :highlight-current-row="true"
    v-loading="isLoading"
    row-key="id"
    :expand-row-keys="expandRowKeys"
    @expand-change="handleExpandChange"
  >
    <el-table-column prop="title" label="Title" />
    <el-table-column prop="creation_date" label="Date" width="120" />
    <el-table-column label="Status" width="120">
      <template #default="scope">
        <el-tag :type="getColor(scope.row.status)">{{
          scope.row.status
        }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column width="75">
      <template #default="scope">
        <div class="icon-group cursor-pointer text-hover">
          <i
            class="bi bi-pencil-fill color-primary"
            @click="handleUpdate(scope.row)"
          />
          <i
            class="bi bi-trash3-fill color-danger"
            @click="handleDelete(scope.row.id)"
          />
        </div>
      </template>
    </el-table-column>
    <el-table-column type="expand" v-if="showDetails">
      <template #default="scope">
        <div
          class="row reject-item mt-2 mb-4"
          v-if="scope.row.status === 'REJECTED'"
        >
          <div class="col-12">
            <p>
              <span class="text-bold color-danger">Rejection: </span
              >{{ scope.row.comment }}
            </p>
          </div>
        </div>
        <div class="row preview-row mt-4 mb-4">
          <div class="col-12">
            <news-item :item="scope.row" v-if="collection === 'news'" />
            <library-item
              :items="[scope.row]"
              v-if="collection === 'library'"
            />
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <!-- End desktop table -->
  <!-- Start mobile table -->
  <el-table
    class="mobile-user-table"
    :data="items"
    style="width: 100%"
    :border="true"
    :highlight-current-row="true"
    v-loading="isLoading"
    row-key="id"
    @row-click="handleRowClick"
  >
    <el-table-column
      :label="`Latest ${collection === 'news' ? 'news' : 'datasets'} submitted`"
    >
      <template #default="scope">
        <div class="row">
          <div class="col-9">
            <p class="text-bold color-primary mb-1">{{ scope.row.title }}</p>
          </div>
          <div class="col-3 btn-right">
            <el-tag :type="getColor(scope.row.status)">{{
              scope.row.status
            }}</el-tag>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <!-- End mobile table -->
  <!-- Start update modal -->
  <el-dialog v-model="showModal" destroy-on-close>
    <template #header>
      <h4>{{ modalTitle }}</h4>
    </template>
    <app-form
      :form="form"
      :model="model"
      :rules="rules"
      button="Submit"
      button-side="right"
      :itemToUpdate="itemToUpdate"
      @submitted="updateItem"
    />
  </el-dialog>
  <!-- End update modal -->
  <!-- Start view modal (Mobile only) -->
  <el-dialog v-model="showViewModal" destroy-on-close>
    <div
      class="row reject-item mt-2 mb-4"
      v-if="activeItem.status === 'REJECTED'"
    >
      <div class="col-12">
        <p>
          <span class="text-bold color-danger">Rejection: </span
          >{{ activeItem.comment }}
        </p>
      </div>
    </div>
    <div class="row preview-row">
      <div class="col-12 mb-2">
        <news-item :item="activeItem" v-if="collection === 'news'" />
        <library-item :items="[activeItem]" v-if="collection === 'library'" />
      </div>
    </div>
    <div class="btn-right">
      <button class="btn-sm-grey" @click="showViewModal = false">Close</button>
    </div>
  </el-dialog>
  <!-- End view modal -->
  <!-- Start option modal (Mobile only) -->
  <el-dialog
    v-model="showOptionsModal"
    destroy-on-close
    @close="selectedOption = null"
  >
    <div class="row">
      <el-radio-group v-model="selectedOption" @change="handleOptionSelection">
        <el-radio v-for="option in options" size="large" :label="option.value">
          {{ option.label }}
        </el-radio>
      </el-radio-group>
    </div>
  </el-dialog>
  <!-- End option modal -->
</template>

<script>
import Database from '@/utils/services/Database';
import getColor from '@/utils/options/getColor';
import { ElMessageBox } from 'element-plus';

import NewsItem from '@/components/items/News';
import LibraryItem from '@/components/items/Library';

export default {
  name: 'UserActivity',
  props: ['items', 'collection', 'isLoading', 'showDetails'],
  emits: ['update', 'delete'],
  components: {
    NewsItem,
    LibraryItem,
  },
  data() {
    return {
      showModal: false,
      modalTitle: 'Edit',
      form: [],
      model: {},
      rules: {},
      itemToUpdate: {},
      expandRowKeys: [],
      activeItem: {},
      showOptionsModal: false,
      showViewModal: false,
      selectedOption: null,
      options: [
        {
          label: 'View',
          value: 'view',
        },
        {
          label: 'Edit',
          value: 'edit',
        },
        {
          label: 'Delete',
          value: 'delete',
        },
      ],
    };
  },
  methods: {
    async getConfirmation() {
      return ElMessageBox.confirm(
        'If you update this item, it will be sent for approval again. Are you sure you want to continue?',
        'Warning',
        {
          confirmButtonText: 'Update',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      );
    },
    async handleUpdate(item) {
      try {
        const res = await this.getConfirmation();

        if (res === 'confirm') {
          this.form = [];
          this.model = {};
          this.rules = {};
          const { form, model, rules } = await import(
            '@/utils/config/' + this.collection
          );
          this.form = JSON.parse(JSON.stringify(form));
          this.model = JSON.parse(JSON.stringify(model));
          this.rules = JSON.parse(JSON.stringify(rules));
          this.itemToUpdate = item;
          this.showModal = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async handleDelete(id) {
      const res = await ElMessageBox.confirm(
        'Are you sure to delete this item?',
        'Warning',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      );

      if (res === 'confirm') {
        const status = await Database.delete(this.collection, id);
        if (status !== 200)
          return this.$message({
            type: 'error',
            message: 'Something went wrong. Please try again later.',
            offset: 200,
          });

        await this.$message({
          type: 'success',
          message: 'Item deleted successfully.',
          offset: 200,
        });

        this.showModal = false;
        this.$emit('delete');
      }
    },
    async updateItem(item) {
      const status = await Database.update(
        this.collection,
        this.itemToUpdate.id,
        item
      );

      if (status !== 200)
        return this.$message({
          type: 'error',
          message: 'Something went wrong. Please try again later.',
          offset: 200,
        });

      await this.$message({
        type: 'success',
        message: 'Item updated successfully.',
        offset: 200,
      });

      this.showModal = false;
      this.$emit('update');
    },
    getColor(status) {
      return getColor(status);
    },
    handleExpandChange(row, expandedRows) {
      const length = expandedRows.length;

      if (length) this.expandRowKeys = [row.id];
    },
    handleRowClick(row) {
      this.activeItem = row;
      this.showOptionsModal = true;
    },
    handleOptionSelection() {
      if (this.selectedOption === 'view') {
        this.showOptionsModal = false;
        this.showViewModal = true;
      }

      if (this.selectedOption === 'edit') {
        this.showOptionsModal = false;
        this.handleUpdate(this.activeItem);
      }

      if (this.selectedOption === 'delete') {
        this.showOptionsModal = false;
        this.handleDelete(this.activeItem.id);
      }
    },
  },
};
</script>

<style lang="scss">
.el-table {
  .el-scrollbar__bar.is-horizontal {
    display: none !important;
  }
}

.icon-group {
  display: flex;
  justify-content: center;
  align-items: center;
  i:nth-child(1) {
    margin-right: 0.5rem;
  }
}

.reject-item {
  display: flex;
  justify-content: center;
  align-items: center;
  .col-12 {
    max-width: 80%;
    border: 1px solid $danger;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: rgba($danger, 0.1);
    p {
      margin-bottom: 0;
    }
  }
}

.preview-row {
  .col-12 {
    max-width: 90%;
    margin: 0 auto;
    .news-item {
      padding-bottom: 1rem;
      .tags {
        margin-bottom: 0.5rem;
      }
      .el-tag {
        margin-right: 0.5rem;
      }
      hr {
        display: none;
      }
    }
  }
}

.mobile-user-table {
  display: none;
}

@include bp-down(lg) {
  .desktop-user-table {
    display: none;
  }
  .mobile-user-table {
    display: flex;
    p {
      word-break: break-word !important;
    }
  }

  .icon-group {
    i {
      margin-right: 0.5rem;
    }
  }

  .preview-row {
    --bs-gutter-x: 0;
    width: 100%;
  }
  .reject-item {
    margin-bottom: 0 !important;
  }
}

.el-radio.el-radio--large .el-radio__inner {
  height: 20px;
  width: 20px !important;
}
.el-radio-group {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  max-width: 80%;
  margin: 0 auto;
  .el-radio {
    border-bottom: 1px solid $light-grey;
    height: 50px;
    width: 100%;
    &__label {
      font-size: 20px !important;
      font-family: $accent-font;
      padding-left: 1rem;
    }
    &__inner {
      height: 25px;
      width: 25px;
    }
  }
}
</style>
