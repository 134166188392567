<template>
  <div class="container">
    <app-title title="My activity" :underline="false" />
    <el-tabs v-model="activeTab" class="demo-tabs" @tab-click="handleTabChange">
      <el-tab-pane label="News" name="news" />
      <el-tab-pane label="Library" name="library" />
    </el-tabs>
    <div class="row pagination-btn-row">
      <div class="col-12 col-lg-6 mb-2 create-col">
        <button class="btn-sm-accent" @click="handleCreate">Create new</button>
      </div>
      <div class="col-12 col-lg-6 btn-right mb-2">
        <app-pagination
          :total="total"
          :current-page="page"
          :page-size="pageSize"
          @page-change="handlePageChange"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <all-activity
          :items="items"
          :collection="activeTab"
          :is-loading="isLoading"
          :show-details="true"
          @update="getData"
          @delete="getData"
        />
      </div>
    </div>
  </div>
  <el-dialog v-model="showModal" destroy-on-close>
    <template #header>
      <h4>{{ modalTitle }}</h4>
    </template>
    <app-form
      :form="form"
      :model="model"
      :rules="rules"
      button="Submit"
      button-side="right"
      @submitted="saveItem"
    />
  </el-dialog>
</template>

<script>
import AllActivity from '@/components/user/Activity';
import Database from '@/utils/services/Database';

export default {
  name: 'UserActivity',
  components: {
    AllActivity,
  },
  data() {
    return {
      activeTab: 'news',
      items: [],
      showModal: false,
      form: [],
      rules: {},
      model: {},
      page: 1,
      total: 1,
      pageSize: 10,
      modalTitle: 'Submit news',
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const { data, total } = await Database.getActivity({
        type: 'all_activities',
        collection: this.activeTab,
        limit: this.pageSize,
        offset: this.page * this.pageSize - this.pageSize,
      });

      this.items = data;
      this.total = total || 1;
      this.isLoading = false;
    },
    async handleTabChange(tab) {
      this.activeTab = tab.paneName;
      if (this.activeTab === 'news') this.modalTitle = 'Submit news';
      else this.modalTitle = 'Submit dataset';
      await this.getData();
    },
    async handlePageChange(val) {
      this.page = val;
      await this.getData();
    },
    async handleCreate() {
      this.form = [];
      this.model = {};
      this.rules = {};
      const { form, model, rules } = await import(
        '@/utils/config/' + this.activeTab
      );
      this.form = JSON.parse(JSON.stringify(form));
      this.model = JSON.parse(JSON.stringify(model));
      this.rules = JSON.parse(JSON.stringify(rules));
      this.showModal = true;
    },
    async saveItem(data) {
      const status = await Database.create(this.activeTab, data);

      if (status !== 201)
        return this.$message({
          type: 'error',
          message: 'Something went wrong. Please try again later.',
          offset: 200,
        });

      this.$message({
        type: 'success',
        message: 'Item created successfully.',
        offset: 200,
      });

      this.showModal = false;
      this.page = 1;
      await this.getData();
    },
  },
};
</script>

<style lang="scss">
.el-tabs__item {
  font-size: 0.9rem !important;
}

.pagination-btn-row {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@include bp-down(lg) {
  .pagination-btn-row {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    .create-col {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1rem !important;
    }
  }
}
</style>
